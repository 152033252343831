import React, { useState, useEffect } from "react"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Col, Card, Rate, Form, Input, Button, Tag } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { Helmet } from 'react-helmet'
import _ from "lodash"

import EnquiryForm from "./EnquiryModal"
import CatalogueForm from "./CatalogueModal"
import ImageDisplay from "components/ImageDisplay"
import WithHOC from "./actions"

import "./index.scss"

const tagColor = [
  "magenta",
  "red",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "blue",
  "geekblue",
  "purple",
]
const ProductDetails = (props) => {
  const {
    selectedProduct,
    reviews,
    reviewed,
    getProduct,
    getReviews,
    addReview,
    sendMessage,
    requestCatalogue,
    showEnquiryForm,
    showCatalogueForm,
    onChangeDataHOC,
  } = props
  const { id } = useParams()
  const navigate = useNavigate()
  const [rating, updateRating] = useState(0)
  const onSubmit = (formData) => {
    addReview(id, { ...formData, rating: rating })
  }

  useEffect(() => {
    getProduct(id)
    getReviews(id)
  }, [])

  return (
    <div className="product-details">
      <Helmet>
        <title>{ `NF ${selectedProduct.product_name}` }</title>
      </Helmet>
      <Button type="text" onClick={() => navigate(-1)}>
        <ArrowLeftOutlined />
        Back
      </Button>
      <Row gutter={[0,36]}>
        <Col xs={24} md={8}>
          <ImageDisplay images={selectedProduct.product_images} />
        </Col>
        <Col xs={24} md={16}>
          <Card className="p-5 h-100">
            <h2>{selectedProduct.product_name}</h2>
            <div className="mb-4">
              {selectedProduct.categories?.map((category, index) => (
                <Tag color={tagColor[index]}>{category}</Tag>
              ))}
            </div>
            <p className="product-description mb-4">
              {selectedProduct.product_description}
            </p>
            <Button
              className="catalogue-btn mb-4"
              type="success"
              onClick={() => onChangeDataHOC("showCatalogueForm", true)}
            >
              Get Catalogue
            </Button>
            <Button
              type="primary"
              onClick={() => onChangeDataHOC("showEnquiryForm", true)}
            >
              Send an enquiry
            </Button>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card>
            <Row>
              <Col xs={24} md={8}>
                <div className="mb-5">
                  <h5 className="m-0">Material</h5>
                  <ul>
                    {selectedProduct.product_materials?.map((material) => (
                      <li>{material}</li>
                    ))}
                  </ul>
                </div>
                <div className="mb-5">
                  <h5 className="m-0">Size</h5>
                  <ul>
                    {selectedProduct.product_surfaces?.map((surface) => (
                      <li>{surface}</li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col xs={24} md={16}>
                <h5>Features</h5>
                <div>{selectedProduct.feature}</div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card>
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <div>
                  <h5>Review ({reviews.length})</h5>
                </div>
                <div style={{ overflowY: "scroll", height: "450px" }}>
                  {reviews.map((review, index) => (
                    <Card className="mb-2">
                      <Rate disabled value={review.rating} />
                      <div style={{ fontSize: "12px" }}>{review.username}</div>
                      <div>{review.reviews}</div>
                    </Card>
                  ))}
                </div>
              </Col>
              {!reviewed ? (
                <Col xs={24} md={16}>
                  <Card>
                    <div>Be the first to review</div>
                    <div>
                      Your email address will not be published. Required fields
                      are marked *
                    </div>
                    <Rate
                      className="py-2"
                      onChange={(val) => updateRating(val)}
                    />
                    <Form
                      name="review-form"
                      onFinish={onSubmit}
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Review"
                        name="reviews"
                        rules={[
                          {
                            required: true,
                            message: "Please input your review",
                          },
                        ]}
                      >
                        <Input.TextArea placeholder="Tell Us Your Thoughts" />
                      </Form.Item>
                      <Row className="m-0" gutter={10}>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Name"
                            name="username"
                            rules={[
                              {
                                required: true,
                                message: "Please input your name",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Name" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please input your email",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Email" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </Card>
                </Col>
              ) : (
                <Col xs={24} md={16}>
                  <div>Thanks for your rating</div>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
      {showEnquiryForm && (
        <EnquiryForm
          sendMessage={sendMessage}
          onCancel={() => onChangeDataHOC("showEnquiryForm", false)}
        />
      )}
      {showCatalogueForm && (
        <CatalogueForm
          product_id={id}
          requestCatalogue={requestCatalogue}
          onCancel={() => onChangeDataHOC("showCatalogueForm", false)}
        />
      )}
    </div>
  )
}

export default WithHOC(ProductDetails)
