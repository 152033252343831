import React, { Component } from "react"
import _ from 'lodash'

import { Post } from "utils/axios"
import { requestSuccess } from "utils/requestHandler"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      enquirySent: false,
    }

    onChangeDataHOC = (key, val) => this.setState({ [key]: val })

    load = (param) => this.setState({ loading: param })

    sendMessage = (data) =>
      Post(
        `/message/send`,
        data,
        this.sendMessageSuccess,
        ErrorHandler,
        this.load
      )
    sendMessageSuccess = () => {
      this.setState({ enquirySent: true }, () => requestSuccess("Enquiry Sent!"))
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          sendMessage={this.sendMessage}
          onChangeDataHOC={this.onChangeDataHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
