import React, { Component } from "react"
import _ from "lodash"

import { requestSuccess } from "utils/requestHandler"
import { Get, Post, Put, Delete } from "utils/axios"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      catalogues: [],
      selectedCatalogue: {},
      uploadedCatalogue: [],
      showCatalogueModal: false,
    }

    onChangeCatalogueHOC = (key, val) => this.setState({ [key]: val })

    load = (param) => this.setState({ loading: param })

    getCatalogue = (product_id) =>
      Get(
        `/product/${product_id}/catalogue`,
        this.getCatalogueSuccess,
        ErrorHandler,
        this.load
      )
    getCatalogueSuccess = (payload) => {
      this.setState({
        selectedCatalogue: payload.data[0],
        showCatalogueModal: true,
      })
    }

    createCatalogue = () =>
      Post(
        `/product/${this.props.selectedProduct.product_id}/catalogue`,
        { catalogue_blob: this.state.uploadedCatalogue[0] },
        this.createCatalogueSuccess,
        ErrorHandler,
        this.load
      )
    createCatalogueSuccess = () => {
      requestSuccess("Added successfully")
      this.setState({ showCatalogueModal: false, uploadedCatalogue: [] })
    }

    deleteCatalogue = () =>
      Delete(
        `/product/${this.props.selectedProduct.product_id}/catalogue/${this.state.selectedCatalogue.product_id}`,
        this.deleteCatalogueSuccess,
        ErrorHandler,
        this.load
      )
    deleteCatalogueSuccess = () => {
      requestSuccess("Deleted successfully")
      this.setState({ showCatalogueModal: false, selectedCatalogue: {} })
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadCatalogue={this.state.loading}
          getCatalogues={this.getCatalogues}
          getCatalogue={this.getCatalogue}
          createCatalogue={this.createCatalogue}
          updateCatalogue={this.updateCatalogue}
          deleteCatalogue={this.deleteCatalogue}
          onChangeCatalogueHOC={this.onChangeCatalogueHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
