import React from "react"
import { Col, Row } from "antd"
import { Helmet } from 'react-helmet'

import { LoremIpsum } from "utils/loremIpsum"
import ProductItem from "components/ProductItem"

const Gallery = () => {
  return (
    <div>
      <Helmet>
        <title>{"NF Gallery"}</title>
      </Helmet>
      <Row>
        {Array.from({ length: 8 }, (_, index) => index + 1).map(
          (val, index) => (
            <Col md={6} xs={12}>
              <ProductItem
                showButton
                className="m-3"
                imgSrc="/image-placeholder.jpg"
              />
            </Col>
          )
        )}
      </Row>
      <Row>
        <Col md={12} xs={24} className="px-3">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/7S85apx0_EI?si=FtX4_RHZlSISzVWh"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </Col>
        <Col md={12} xs={24} className="px-3">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/f2xGxd9xPYA?si=Vfg19VjnuGsBg9P8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </Col>
      </Row>
      <Row className="full-width-container py-5">
        <Col span={24}>
          <div className="mx-5 text-center d-flex flex-column align-items-center">
            <h1>TITLE</h1>
            <p>{LoremIpsum}</p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Gallery
