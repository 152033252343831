import React, { Component } from "react"
import { requestSuccess } from "utils/requestHandler"
import { Get, Post, Put, Delete } from "utils/axios"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      categories: [],
      selectedSubCategory: {},
      showCreateModal: false,
      showUpdateModal: false,
      showDeleteModal: false,
    }

    onChangeSubCategoryHOC = (key, val) => this.setState({ [key]: val })

    load = (param) => this.setState({ loading: param })

    getSubCategory = (id, type) =>
      Get(
        `/subcategory/${id}`,
        (payload) => this.getSubCategorySuccess(payload, type),
        ErrorHandler,
        this.load
      )
    getSubCategorySuccess = (payload, type) => {
      this.setState({
        selectedSubCategory: payload.data,
        showUpdateModal: type === "update",
        showDeleteModal: type === "delete",
      })
    }

    createSubCategory = (data) =>
      Post(
        `/subcategory`,
        data,
        this.createSubCategorySuccess,
        ErrorHandler,
        this.load
      )
    createSubCategorySuccess = () => {
      requestSuccess("Created successfully")
      this.setState({ showCreateModal: false, selectedSubCategory: {} }, () => this.props.getCategory( this.props.selectedCategory.category_id, "subcategory" ))
    }

    updateSubCategory = (data) =>
      Put(
        `/subcategory/${this.state.selectedSubCategory.subcategory_id}`,
        data,
        this.updateSubCategorySuccess,
        ErrorHandler,
        this.load
      )
    updateSubCategorySuccess = () => {
      requestSuccess("Updated successfully")
      this.setState({ showUpdateModal: false, selectedSubCategory: {} }, () => this.props.getCategory( this.props.selectedCategory.category_id, "subcategory" ))
    }

    deleteSubCategory = () =>
      Delete(
        `/subcategory/${this.state.selectedSubCategory.subcategory_id}`,
        this.deleteSubCategorySuccess,
        ErrorHandler,
        this.load
      )
    deleteSubCategorySuccess = () => {
      requestSuccess("Deleted successfully")
      this.setState({ showDeleteModal: false, selectedSubCategory: {} }, () => this.props.getCategory( this.props.selectedCategory.category_id, "subcategory" ))
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadSubCategory={this.state.loading}
          getSubCategories={this.getSubCategories}
          getSubCategory={this.getSubCategory}
          createSubCategory={this.createSubCategory}
          updateSubCategory={this.updateSubCategory}
          deleteSubCategory={this.deleteSubCategory}
          onChangeSubCategoryHOC={this.onChangeSubCategoryHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
