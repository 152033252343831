import React, { useEffect } from "react"
import { Button, Divider, Row, Col, Empty, Modal } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import _ from "lodash"
import { FilePond } from "react-filepond"

import LoadingOverlay from "components/LoadingOverlay"
import WithHOC from "./actions/images"

const ProductImages = (props) => {
  let {
    onLoadImage,
    selectedProduct,
    uploadedImages,
    productImages,
    showDeleteModal,
    getImages,
    addImages,
    deleteImages,
    onChangeImageHOC,
  } = props

  useEffect(() => {
    getImages(selectedProduct.product_id)
  }, [selectedProduct.product_id])

  return (
    <div>
      <FilePond
        files={uploadedImages}
        className="mb-4"
        acceptedFileTypes={["image/*"]}
        onupdatefiles={(value) => {
          if (!_.isEmpty(value)) {
            const reader = new FileReader()
            reader.onload = () => {
              onChangeImageHOC("uploadedImages", [reader.result])
            }
            reader.readAsDataURL(value[0]?.file)
          } else {
            onChangeImageHOC("uploadedImages", [])
          }
        }}
        allowMultiple={false}
      />
      <Button
        className="ml-auto"
        disabled={_.isEmpty(uploadedImages)}
        onClick={() => addImages(uploadedImages)}
      >
        Add
      </Button>
      <Divider />
      <Row gutter={[16, 16]}>
        {productImages.length > 0 ? (
          productImages.map((image, index) => {
            return (
              <Col xs={24} md={8}>
                <div style={{ border: "1px solid #dedede", height: "100%" }}>
                  <Button
                    danger
                    style={{
                      backgroundColor: "transparent",
                      position: "absolute",
                      right: "16px",
                      top: "8px",
                    }}
                    onClick={() => {
                      onChangeImageHOC("selectedImage", image)
                      onChangeImageHOC("showDeleteModal", true)
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={image.image_blob}
                    key={index}
                  />
                </div>
              </Col>
            )
          })
        ) : (
          <Col xs={24}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        )}
      </Row>
      <Modal
        open={showDeleteModal}
        title="Delete Product Image"
        onOk={() => deleteImages()}
        onCancel={() => onChangeImageHOC("showDeleteModal", false)}
      >
        Are you sure you want to delete this product image?
      </Modal>
      {onLoadImage && <LoadingOverlay />}
    </div>
  )
}

export default WithHOC(ProductImages)
