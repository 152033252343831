import React, { Component } from "react"
import { Get } from "utils/axios"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      categories: [],
      subCategories: [],
    }

    load = (param) => this.setState({ loading: param })

    onChangeCategoryHOC = (key, val) => this.setState({ [key]: val })

    getCategories = () =>
      Get(
        `/category`,
        this.getCategoriesSuccess,
        ErrorHandler,
        this.load
      )
    getCategoriesSuccess = (payload) => {
      this.setState({ categories: payload.data })
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadCategory={this.state.loading}
          getCategories={this.getCategories}
          onChangeCategoryHOC={this.onChangeCategoryHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
