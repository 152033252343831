import React, { useEffect } from "react"
import { Form, Select, Input, Button, Row, Col, Space } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import _ from "lodash"
const { TextArea } = Input

const DataForm = (props) => {
  const [form] = Form.useForm()
  const chosenCategory = Form.useWatch("categories", form)

  useEffect(() => {
    if (!_.isEmpty(props.selectedProduct) && props.showUpdateModal) {
      form.setFieldsValue({ ...props.selectedProduct })
    }
  }, [props.selectedProduct])

  useEffect(() => {
    let tmp = []
    let tmpSubCategories =
      form.getFieldValue("subcategories")?.length > 0
        ? form.getFieldValue("subcategories")
        : props.selectedProduct?.subcategories || []
    if (chosenCategory) {
      let selectedCategory = _.filter(props.categories, (val) =>
        chosenCategory.includes(val.category_id)
      )
      _.forEach(selectedCategory, (category) => {
        tmp = [...tmp, ...category.subcategory]
      })
    }
    props.onChangeCategoryHOC("subCategories", tmp)
    form.setFieldsValue({
      subcategories: _.filter(
        tmpSubCategories,
        (val) => _.findIndex(tmp, { subcategory_id: val }) > -1
      ),
    })
  }, [chosenCategory, props.selectedProduct])

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      onFinish={props.onSubmit}
    >
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            label="Product Name"
            name="product_name"
            rules={[{ required: true, message: "Please enter product name" }]}
          >
            <Input size="large" placeholder="Product Name" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label="Product Description"
            name="product_description"
            rules={[
              { required: true, message: "Please enter product description" },
            ]}
          >
            <TextArea
              type="textarea"
              size="large"
              placeholder="Product Description"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            label="Category"
            name="categories"
            rules={[{ required: true, message: "Please select category" }]}
          >
            <Select mode="multiple" size="large" placeholder="Category">
              {props.categories.map((category, index) => {
                return (
                  <option value={category.category_id}>
                    {category.category_name}
                  </option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label="Sub-Category"
            name="subcategories"
            rules={[{ required: true, message: "Please select sub-category" }]}
          >
            <Select mode="multiple" size="large" placeholder="Sub-Category">
              {props.subCategories.map((subCategory, index) => {
                return (
                  <option value={subCategory.subcategory_id}>
                    {subCategory.subcategory_name}
                  </option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item label="Material">
            <Form.List name="product_materials">
              {(subFields, subOpt) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 16,
                  }}
                >
                  {subFields.map((subField) => (
                    <Space key={subField.key}>
                      <Form.Item
                        noStyle
                        name={[subField.name]}
                        rules={[
                          { required: true, message: "Please enter material" },
                        ]}
                      >
                        <Input placeholder="Material" />
                      </Form.Item>
                      <CloseOutlined
                        onClick={() => {
                          subOpt.remove(subField.name)
                        }}
                      />
                    </Space>
                  ))}
                  <Button type="dashed" onClick={() => subOpt.add()} block>
                    + Add Material
                  </Button>
                </div>
              )}
            </Form.List>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label="Surfaces (Size)">
            <Form.List name="product_surfaces">
              {(subFields, subOpt) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 16,
                  }}
                >
                  {subFields.map((subField) => (
                    <Space key={subField.key}>
                      <Form.Item
                        noStyle
                        name={[subField.name]}
                        rules={[
                          { required: true, message: "Please enter surfaces" },
                        ]}
                      >
                        <Input placeholder="Surfaces" />
                      </Form.Item>
                      <CloseOutlined
                        onClick={() => {
                          subOpt.remove(subField.name)
                        }}
                      />
                    </Space>
                  ))}
                  <Button type="dashed" onClick={() => subOpt.add()} block>
                    + Add Surfaces
                  </Button>
                </div>
              )}
            </Form.List>
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit" className="ml-auto">
        Save
      </Button>
    </Form>
  )
}

export default DataForm
