import React, { useEffect } from 'react'
import _ from 'lodash'
import { Carousel, Menu, ConfigProvider, Card, Row, Col, Button, Dropdown } from 'antd'
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import { MenuOutlined } from '@ant-design/icons'

import { router } from 'router'
import WithHOC from './actions.js'
import { LoremIpsum } from 'utils/loremIpsum'
import ScrollToTop from 'components/ScrollToTop'

import './index.scss'

const contentStyle = {
  height: '50vh',
  color: '#fff',
  lineHeight: '50vh',
  textAlign: 'center',
  background: '#364d79',
};

const PublicPage = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const menuItems = _.filter(_.find( router, { label: "Public" } ).children, child => child.key )

  useEffect(() => {
    props.getCompany()
    props.getBanners()
    props.getProducts()
  }, [])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#06004B',
          colorFillSecondary: '#EAEAEA'
        }
      }}>
      <div className='outer-container'>
        <div className='header-menu'>
          <img className='logo' src="/logo-placeholder.png" onClick={() => navigate('/')}/>
          <div className='desktop-menu'>
            <Menu
              mode="horizontal"
              items={menuItems}
              onClick={(val) => navigate( val.key )}
              selectedKeys={[ location.pathname.includes("/products") ? "/products" : location.pathname ]}/>
          </div>
          <Dropdown
            className='mobile-menu'
            menu={{
              items: [
                {
                  key: 1,
                  label: "Home",
                },
                {
                  key: 2,
                  label: "About Us",
                },
                {
                  key: 3,
                  label: "Products",
                },
                {
                  key: 4,
                  label: "Gallery",
                },
                {
                  key: 5,
                  label: "Contact",
                },
              ],
              onClick: (val) => {
                switch (val.key) {
                  case "1":
                    navigate('/')
                    break
                  case "2":
                    navigate('/about-us')
                    break
                  case "3":
                    navigate('/products')
                    break
                  case "4":
                    navigate('/gallery')
                    break
                  case "5":
                    navigate('/contact')
                    break
                  default:
                }
              }
            }}
            trigger={["click"]}
          >
            <Button type="text"><MenuOutlined /></Button>
          </Dropdown>
        </div>
        <Carousel autoplay dots={false}>
          {
            props.banners.map( banner => (
              <div key={banner.banner_id}>
                <img className='banner-image' src={banner.banner_blob}/>
              </div>
            ))
          }
        </Carousel>
        <Card className='content-container'>
          <Outlet context={{products: props.products, company: props.selectedCompany}}/>
        </Card>
        <div className='footer-container primary-theme'>
          <Row gutter={[24, 36]}>
            <Col className="footer-content" xs={24} lg={8}>
              <div>
                <h4>ABOUT US</h4>
                <div>
                  {LoremIpsum}
                </div>
              </div>
            </Col>
            <Col className="footer-content" xs={24} lg={8}>
              <div>
                <h4>PRODUCTS</h4>
                {
                  props.products.slice(0,5).map( product => (
                    <Button type='link' style={{color: "#ffffff", padding: 0}} onClick={()=>navigate(`/products/${product.product_id}`)}>{ product.product_name }</Button>
                  ) )
                }
              </div>
            </Col>
            <Col className="footer-content" xs={24} lg={8}>
              <div>
                <h4>CONTACT US</h4>
                <div>{props.selectedCompany.address_line_1}</div>
                <div>{props.selectedCompany.address_line_2}</div>
                <div>{props.selectedCompany.address_line_3}</div>
                <div>{props.selectedCompany.address_line_4}</div>
                <br/>
                <div>TEL: {props.selectedCompany.contact_no}</div>
                <div>FAX: {props.selectedCompany.fax_no}</div>
                <br/>
                <div>EMAIL: {props.selectedCompany.email}</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ScrollToTop/>
    </ConfigProvider>
  )
}

export default WithHOC(PublicPage)