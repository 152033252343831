import React, { Component } from "react"
import _ from 'lodash'

import { Get } from "utils/axios"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedCompany: {},
      banners: [],
      products: []
    }

    onChangeCompanyHOC = (key, val) => this.setState({ [key]: val })

    load = (param) => this.setState({ loading: param })

    getCompany = () =>
      Get(
        `/company/detail`,
        this.getCompanySuccess,
        ErrorHandler,
        this.load
      )
    getCompanySuccess = (payload) => {
      this.setState({
        selectedCompany: payload.data
      })
    }

    getBanners = () =>
      Get(
        `/banner`,
        this.getBannersSuccess,
        ErrorHandler,
        this.load
      )
    getBannersSuccess = (payload) => {
      this.setState({ banners: _.orderBy(payload.data, ['order'], ['asc']) })
    }

    getProducts = () =>
      Get(
        `/product`,
        this.getProductsSuccess,
        ErrorHandler,
        this.load
      )
    getProductsSuccess = (payload) => {
      this.setState({ products: payload.data })
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadData={this.state.loading}
          getCompany={this.getCompany}
          getBanners={this.getBanners}
          getProducts={this.getProducts}
          onChangeCompanyHOC={this.onChangeCompanyHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
