import React, { useEffect } from "react"
import { Table, Space, Button, Dropdown, Modal, Tag } from "antd"
import { compose } from "redux"
import _ from "lodash"
import { Helmet } from 'react-helmet'
import { FilePond } from "react-filepond"

import LoadingOverlay from "components/LoadingOverlay"
import { truncateTextWithEllipsis } from "utils/utils"
import { TagColor } from "utils/TagColor"
import WithHOC from "./actions"
import WithOptionHOC from "./actions/categories"
import WithCatalogue from "./actions/catalogue"
import DataForm from "./form"
import ProductImages from "./Images"
import PDFViewer from "components/PDFViewer"

const actionItem = [
  {
    key: 1,
    label: "Update",
  },
  {
    key: 2,
    label: "Manage Images",
  },
  {
    key: 3,
    label: "Manage Catalogue",
  },
  {
    key: 4,
    label: "Delete",
  },
]

const Products = (props) => {
  let {
    products,
    selectedProduct,
    onLoadProduct,
    onLoadCategory,
    showCreateModal,
    showUpdateModal,
    showDeleteModal,
    showImageModal,
    createProduct,
    updateProduct,
    deleteProduct,
    onChangeProductHOC,
    getProducts,
    getProduct,
    getCategories,
    showCatalogueModal,
    uploadedCatalogue,
    selectedCatalogue,
    createCatalogue,
    getCatalogue,
    deleteCatalogue,
    onChangeCatalogueHOC,
  } = props

  useEffect(() => {
    getProducts()
    getCategories()
  }, [])

  const onCreate = (formData) => {
    createProduct(formData)
  }

  const onUpdate = (formData) => {
    updateProduct(formData)
  }

  const onDelete = () => {
    deleteProduct()
  }

  const actionFunction = (key, ...args) => {
    switch (key) {
      case "1":
        getProduct(args[0].product_id, "update")
        break
      case "2":
        getProduct(args[0].product_id, "image")
        break
      case "3":
        getProduct(args[0].product_id)
        getCatalogue(args[0].product_id, "delete")
        break
      case "4":
        getProduct(args[0].product_id, "delete")
        break
      default:
    }
  }

  const columns = [
    {
      key: "product_name",
      title: "Product Name",
      dataIndex: "product_name",
    },
    {
      key: "product_description",
      title: "Product Description",
      dataIndex: "product_description",
      render: (text) => truncateTextWithEllipsis(text, 80),
    },
    {
      key: "material",
      title: "Material",
      dataIndex: "product_materials",
      render: (text) => {
        return text.map((item, index) => {
          return (
            <Tag color={TagColor[index % 11]} key={index}>
              {item}
            </Tag>
          )
        })
      },
    },
    {
      key: "size",
      title: "Size",
      dataIndex: "product_surfaces",
      render: (text) => {
        return text.map((item, index) => {
          return (
            <Tag color={TagColor[index % 11]} key={index}>
              {item}
            </Tag>
          )
        })
      },
    },
    {
      key: "category",
      title: "Category",
      dataIndex: "categories",
      render: (text) => {
        return text.map((item, index) => {
          return (
            <Tag color={TagColor[index % 11]} key={index}>
              {item}
            </Tag>
          )
        })
      },
    },
    {
      key: "sub_category",
      title: "Sub-category",
      dataIndex: "subcategories",
      render: (text) => {
        return text.map((item, index) => {
          return (
            <Tag color={TagColor[index % 11]} key={index}>
              {item}
            </Tag>
          )
        })
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: actionItem,
              onClick: (val) => actionFunction(val.key, record),
            }}
            trigger={["click"]}
          >
            <Button>Actions</Button>
          </Dropdown>
        </Space>
      ),
    },
  ]

  return (
    <div>
      <Helmet>
        <title>{"NF Product Management"}</title>
      </Helmet>
      <Button
        type="primary"
        className="mb-2 ml-auto"
        onClick={() => onChangeProductHOC("showCreateModal", true)}
      >
        Add Product
      </Button>
      <Table dataSource={products} columns={columns} rowKey={"id"} />
      {showCreateModal && (
        <Modal
          open={true}
          width={1000}
          title="Create Product"
          footer={false}
          onCancel={() => onChangeProductHOC("showCreateModal", false)}
        >
          <DataForm {...props} onSubmit={onCreate} />
        </Modal>
      )}
      {showUpdateModal && (
        <Modal
          open={true}
          width={1000}
          title="Update Product"
          footer={false}
          onCancel={() => onChangeProductHOC("showUpdateModal", false)}
        >
          <DataForm {...props} onSubmit={onUpdate} />
        </Modal>
      )}
      <Modal
        open={showDeleteModal}
        title="Delete Product"
        onOk={onDelete}
        onCancel={() => onChangeProductHOC("showDeleteModal", false)}
      >
        Are you sure you want to delete this product?
      </Modal>
      <Modal
        width={1000}
        open={showImageModal}
        title="Product Images"
        footer={false}
        onCancel={() => onChangeProductHOC("showImageModal", false)}
      >
        <ProductImages
          selectedProduct={selectedProduct}
          getProduct={getProduct}
        />
      </Modal>
      <Modal
        open={showCatalogueModal}
        title="Add Catalogue"
        footer={null}
        onCancel={() => onChangeCatalogueHOC("showCatalogueModal", false)}
      >
        {_.isEmpty(selectedCatalogue) ? (
          <>
            <FilePond
              files={uploadedCatalogue}
              className="mb-4"
              acceptedFileTypes={["application/pdf"]}
              onupdatefiles={(value) => {
                if (!_.isEmpty(value)) {
                  const reader = new FileReader()
                  reader.onload = () => {
                    onChangeCatalogueHOC("uploadedCatalogue", [reader.result])
                  }
                  reader.readAsDataURL(value[0]?.file)
                } else {
                  onChangeCatalogueHOC("uploadedCatalogue", [])
                }
              }}
              allowMultiple={false}
            />
            <Button
              className="ml-auto"
              disabled={_.isEmpty(uploadedCatalogue)}
              onClick={() => createCatalogue()}
            >
              Add
            </Button>
          </>
        ) : (
          <>
            <PDFViewer fileUrl={selectedCatalogue.catalogue_blob}/>
            <Button
              danger
              className="ml-auto"
              onClick={() => deleteCatalogue()}
            >
              Remove
            </Button>
          </>
        )}
      </Modal>
      {(onLoadProduct || onLoadCategory) && <LoadingOverlay />}
    </div>
  )
}

export default compose(WithOptionHOC, WithHOC, WithCatalogue)(Products)
