import React from "react"
import { Row, Col, Button } from "antd"
import { LoremIpsum } from "utils/loremIpsum"
import ContentCard from "components/ContentCard"
import MultiCarousel from "components/MultiCarousel"
import { useNavigate, useOutletContext } from "react-router-dom"
import { Helmet } from 'react-helmet'
import _ from "lodash"

import "./index.scss"
import ProductItem from "components/ProductItem"

const HomePage = () => {
  const navigate = useNavigate()
  const {products} = useOutletContext();
  return (
    <div>
      <Helmet>
        <title>{ "NF Homepage" }</title>
      </Helmet>
      <Row className="text-image-container">
        <Col
          xs={24}
          lg={16}
          className="d-flex flex-column justify-content-center px-5 text-part"
        >
          <h2>Our Company</h2>
          <p>{LoremIpsum}</p>
        </Col>
        <Col xs={24} lg={8} className="px-5 image-part">
          <img
            src={"/image-placeholder.jpg"}
            style={{
              borderRadius: "20px",
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} lg={8}>
          <ContentCard className="mx-xs-0 mx-md-5" title={"Title"} content={LoremIpsum} />
        </Col>
        <Col xs={24} lg={8}>
          <ContentCard className="mx-xs-0 mx-md-5" title={"Title"} content={LoremIpsum} />
        </Col>
        <Col xs={24} lg={8}>
          <ContentCard className="mx-xs-0 mx-md-5" title={"Title"} content={LoremIpsum} />
        </Col>
      </Row>
      <Row className="primary-theme full-width-container p-5">
        <Col span={24}>
          <div className="mx-5 text-center d-flex flex-column align-items-center">
            <h1>OUR SERVICE</h1>
            <p>{LoremIpsum}</p>
            <Button className="rounded-button">Learn More</Button>
          </div>
        </Col>
      </Row>
      <h2 className="text-center">Hot Items</h2>
      <MultiCarousel
        xs={1}
        sm={2}
        md={4}
        lg={4}
        xl={5}
        xxl={6}
        infinite={true}
        className="product-item-slider"
      >
        {products.map(
          (product, index) => (
            <ProductItem
              roundCorner
              showButton
              className="mx-3"
              imgSrc={product.product_images[0]}
              text={product.product_name}
              onClick={() => navigate(`/products/${product.product_id}`)}
            />
          )
        )}
      </MultiCarousel>
    </div>
  )
}

export default HomePage
