import React from "react"
import { Modal, Button, Form, Input } from "antd"

const CatalogueForm = (props) => {
  const onSubmit = formData => {
    props.requestCatalogue( props.product_id, formData )
  }
  return (
    <Modal open footer={null} onCancel={props.onCancel}>
      <h4>Get the catalogue</h4>
      <p>Please enter your email and we will sent a copy of the catalogue to your email.</p>
      <Form name="catalogue-form" onFinish={onSubmit} autoComplete="off" layout="vertical">
        <Form.Item
          label="Name"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your name",
            },
          ]}
        >
          <Input size="large" placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="Contact No."
          name="phone"
        >
          <Input size="large" placeholder="Contact No." />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email",
            },
          ]}
        >
          <Input size="large" placeholder="Email" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CatalogueForm
