import React, { Component } from "react"
import { Get, Post } from "utils/axios"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
    }

    load = (param) => this.setState({ loading: param })

    login = (data, callback) =>
      Post(
        `/login`,
        data,
        (payload) => this.loginSuccess(payload, callback),
        ErrorHandler,
        this.load
      )
    loginSuccess = (payload, callback) => {
      callback(payload.access_token)
    }

    checkToken = (callback) =>
      Get(
        `/protected`,
        () => this.checkTokenSuccess(callback),
        ErrorHandler,
        this.load
      )
    checkTokenSuccess = (callback) => {
      callback()
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadImage={this.state.loading}
          login={this.login}
          checkToken={this.checkToken}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
