import React, { useEffect } from "react"
import { Helmet } from 'react-helmet'
import { Table, Card, Button, Space, Dropdown, Modal, Form, Input } from "antd"
import _ from "lodash"

import LoadingOverlay from "components/LoadingOverlay"
import WithHOC from "./actions"

const actionItem = [
  {
    key: 1,
    label: "Delete",
  },
]

const BlockList = (props) => {
  let {
    onLoadData,
    blockLists,
    getBlockLists,
    showCreateModal,
    showDeleteModal,
    addBlockList,
    removeBlockEmail,
    onChangeDataHOC
  } = props
  const [form] = Form.useForm()
  useEffect(() => {
    getBlockLists()
  }, [])

  const onDelete = () => {
    removeBlockEmail()
  }

  const onSubmit = (formData) => {
    addBlockList(formData)
  }

  const actionFunction = (key, ...args) => {
    switch (key) {
      case "1":
        onChangeDataHOC("selectedBlockedEmail", args[0])
        onChangeDataHOC("showDeleteModal", true)
        break
      default:
    }
  }

  const columns = [
    {
      key: "email_regex",
      title: "Blocked Email Pattern",
      dataIndex: "email_regex",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: actionItem,
              onClick: (val) => actionFunction(val.key, record),
            }}
            trigger={["click"]}
          >
            <Button>Actions</Button>
          </Dropdown>
        </Space>
      ),
    }
  ]

  return (
    <>
      <Helmet>
        <title>{"NF Block List"}</title>
      </Helmet>
      <Card>
        <div className="mb-4 d-flex justify-content-between">
            <h3>Block Lists</h3>
            <Button
              type="primary"
              onClick={() => onChangeDataHOC("showCreateModal", true)}
            >
              Block New Email
            </Button>
          </div>
        <Table dataSource={blockLists} columns={columns} rowKey={"id"} />
        {onLoadData && <LoadingOverlay />}
      </Card>
      <Modal
        open={showCreateModal}
        title="Block Email"
        footer={null}
        onCancel={() => onChangeDataHOC("showCreateModal", false)}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          onFinish={onSubmit}
        >
          <Form.Item
            label="Email Pattern (Regex/Full)"
            name="email_regex"
            rules={[{ required: true, message: "Please enter email pattern to be blocked" }]}
          >
            <Input size="large" placeholder="*@namfoong.com" />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="ml-auto">
            Add
          </Button>
        </Form>
      </Modal>
      <Modal
        open={showDeleteModal}
        title="Unblock Email"
        onOk={onDelete}
        onCancel={() => onChangeDataHOC("showDeleteModal", false)}
      >
        Are you sure you want to unblock this email?
      </Modal>
    </>
  )
}

export default WithHOC(BlockList)
