import React, { Component } from "react"
import { requestSuccess } from "utils/requestHandler"
import { Get, Post, Put, Delete } from "utils/axios"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      categories: [],
      selectedCategory: {},
      showSubCategoryModal: false,
      showCreateModal: false,
      showUpdateModal: false,
      showDeleteModal: false,
    }

    onChangeCategoryHOC = (key, val) => this.setState({ [key]: val })

    load = (param) => this.setState({ loading: param })

    getCategories = () =>
      Get(
        `/category`,
        this.getCategoriesSuccess,
        ErrorHandler,
        this.load
      )
    getCategoriesSuccess = (payload) => {
      this.setState({ categories: payload.data })
    }

    getCategory = (id, type) =>
      Get(
        `/category/${id}`,
        (payload) => this.getCategorySuccess(payload, type),
        ErrorHandler,
        this.load
      )
    getCategorySuccess = (payload, type) => {
      this.setState({
        selectedCategory: payload.data,
        showUpdateModal: type === "update",
        showDeleteModal: type === "delete",
        showSubCategoryModal: type === "subcategory",
      })
    }

    createCategory = (data) =>
      Post(
        `/category`,
        data,
        this.createCategorySuccess,
        ErrorHandler,
        this.load
      )
    createCategorySuccess = () => {
      requestSuccess("Created successfully")
      this.setState({ showCreateModal: false, selectedCategory: {} }, () => this.getCategories())
    }

    updateCategory = (data) =>
      Put(
        `/category/${this.state.selectedCategory.category_id}`,
        data,
        this.updateCategorySuccess,
        ErrorHandler,
        this.load
      )
    updateCategorySuccess = () => {
      requestSuccess("Updated successfully")
      this.setState({ showUpdateModal: false, selectedCategory: {} }, () => this.getCategories())
    }

    deleteCategory = () =>
      Delete(
        `/category/${this.state.selectedCategory.category_id}`,
        this.deleteCategorySuccess,
        ErrorHandler,
        this.load
      )
    deleteCategorySuccess = () => {
      requestSuccess("Deleted successfully")
      this.setState({ showDeleteModal: false, selectedCategory: {} }, () => this.getCategories())
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadCategory={this.state.loading}
          getCategories={this.getCategories}
          getCategory={this.getCategory}
          createCategory={this.createCategory}
          updateCategory={this.updateCategory}
          deleteCategory={this.deleteCategory}
          onChangeCategoryHOC={this.onChangeCategoryHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
