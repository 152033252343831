import React, { Component } from "react"
import _ from "lodash"

import { Get, Post, Delete } from "utils/axios"
import { requestSuccess } from "utils/requestHandler"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      blockLists: [],
      selectedBlockedEmail: {},
      showCreateModal: false,
      showDeleteModal: false
    }

    onChangeDataHOC = (key, val) => this.setState({ [key]: val })

    load = (param) => this.setState({ loading: param })

    getBlockLists = () =>
      Get(
        `/block-list`,
        this.getBlockListsSuccess,
        ErrorHandler,
        this.load
      )
    getBlockListsSuccess = (payload) => {
      this.setState({ blockLists: payload.data })
    }

    addBlockList = (data) =>
      Post(
        `/block-list`,
        data,
        this.addBlockListSuccess,
        ErrorHandler,
        this.load
      )
    addBlockListSuccess = (payload) => {
      requestSuccess('Email blocked successfully')
      this.setState({ showCreateModal: false }, () => this.getBlockLists())
    }

    removeBlockEmail = () =>
      Delete(
        `/block-list/${this.state.selectedBlockedEmail.id}`,
        this.removeBlockEmailSuccess,
        ErrorHandler,
        this.load
      )
    removeBlockEmailSuccess = (payload) => {
      requestSuccess('Email unblocked successfully')
      this.setState({ showDeleteModal: false }, () => this.getBlockLists())
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadData={this.state.loading}
          getBlockLists={this.getBlockLists}
          addBlockList={this.addBlockList}
          removeBlockEmail={this.removeBlockEmail}
          onChangeDataHOC={this.onChangeDataHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
