import React from "react";
import { Card } from 'antd'
import "./index.scss"

const ContentCard = props => {
  let {
    className,
    title,
    content,
    Icon
  } = props

  return (
    <div className="content-card-container">
      <div className="top-icon-container">
        <div className="top-icon">
          <div className="square-content">
            {Icon && <Icon/>}
          </div>
        </div>
      </div>
      <Card className={`content-card ${className || ""}`}>
        <div className="icon-container"/>
        <h3>{title || ""}</h3>
        <p>{content || ""}</p>
      </Card>
    </div>
  )
}

export default ContentCard