import React, { Component } from "react"
import { Get } from "utils/axios"
import { ErrorHandler } from "utils/requestError"
import _ from "lodash"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      visitors: [],
    }

    load = (param) => this.setState({ loading: param })

    getVisitor = () =>
      Get(
        `/visitor-record`,
        this.getVisitorSuccess,
        ErrorHandler,
        this.load
      )
    getVisitorSuccess = (payload) => {
      this.setState({
        visitors: _.uniqBy(payload.data, 'visitor_ip')
      })
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadVisitor={this.state.loading}
          getVisitor={this.getVisitor}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
