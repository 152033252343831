import React from "react"
import { Row, Col } from "antd"
import { Helmet } from 'react-helmet'

import { LoremIpsum } from "utils/loremIpsum"
import ProductItem from "components/ProductItem"
import MultiCarousel from "components/MultiCarousel"

const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>{ "NF About Us" }</title>
      </Helmet>
      <Row className="full-width-container py-5">
        <Col span={24}>
          <div className="mx-5 text-center d-flex flex-column align-items-center">
            <h1>OUR SERVICE</h1>
            <p>{LoremIpsum}</p>
          </div>
        </Col>
      </Row>
      <Row gutter={[0, 36]} className="secondary-theme full-width-container py-5">
        <Col xs={24} lg={12}>
          <div className="mx-5 px-5" style={{ textAlign: "justify" }}>
            <h2>VISION</h2>
            <p>{LoremIpsum}</p>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="mx-5 px-5" style={{ textAlign: "justify" }}>
            <h2>MISSION</h2>
            <p>{LoremIpsum}</p>
          </div>
        </Col>
      </Row>
      <MultiCarousel
        xs={1}
        sm={2}
        md={3}
        lg={4}
        xl={5}
        xxl={6}
        infinite
        autoPlay
        autoPlaySpeed={1000}
        customTransition="transform 2000ms ease-in-out"
        transitionDuration={2000}
        removeArrowOnDeviceType={["xs", "sm", "md", "lg", "xl", "xxl"]}
        className="product-item-slider"
      >
        {Array.from({ length: 20 }, (_, index) => index + 1).map(
          (val, index) => (
            <ProductItem
              showButton
              className="mx-3"
              imgSrc="/image-placeholder.jpg"
            />
          )
        )}
      </MultiCarousel>
      <Row className="full-width-container py-5">
        <Col span={24}>
          <div className="mx-5 text-center d-flex flex-column align-items-center">
            <h1>OUR SERVICE</h1>
            <p>{LoremIpsum}</p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AboutUs
