import React, { useState, useEffect } from "react"
import { Row, Col, Card, Menu, Input, Empty } from "antd"
import _ from "lodash"
import { useNavigate, useOutletContext } from "react-router-dom"
import { Helmet } from 'react-helmet'

import WithHOC from "./actions"
import ProductItem from "components/ProductItem"
import "./index.scss"

const Products = (props) => {
  const navigate = useNavigate()
  const { products } = useOutletContext()
  const [categoryMenu, updateCategoryMenu] = useState([])
  const [productList, updateProductList] = useState(products)
  const [query, updateQuery] = useState({})

  useEffect(() => {
    props.getCategories()
  }, [])

  useEffect(() => {
    updateProductList(
      _.filter(
        products,
        (product) =>
          (!query.categories ||
            product.subcategories.includes(query.categories)) &&
          (!query.name ||
            product.product_name
              .toLowerCase()
              .includes(query.name.toLowerCase()))
      )
    )
  }, [query])

  useEffect(() => {
    if (props.categories.length > 0) {
      updateCategoryMenu([
        { key: "All", label: "All" },
        ..._.map(props.categories, (category) => ({
          key: `C${category.category_id}`,
          label: category.category_name,
          children: _.map(category.subcategory, (subcategory) => ({
            key: subcategory.subcategory_name,
            label: subcategory.subcategory_name,
          })),
        })),
      ])
    } else {
      updateCategoryMenu([])
    }
  }, [props.categories])

  const onClickMenu = (e) => {
    if (e.key === "All") {
      updateQuery({ ...query, categories: undefined })
    } else {
      updateQuery({ ...query, categories: e.key })
    }
  }

  return (
    <>
      <Helmet>
        <title>{"NF Product Listing"}</title>
      </Helmet>
      <Row gutter={[0, 36]} className="product-page">
        <Col xs={24} md={6}>
          <Card className="product-page-menu">
            <h3>Product Categories</h3>
            {!_.isEmpty(categoryMenu) && (
              <Menu
                onClick={onClickMenu}
                defaultSelectedKeys={["All"]}
                defaultOpenKeys={categoryMenu.map((val) => val.key)}
                mode="inline"
                items={categoryMenu}
              />
            )}
          </Card>
        </Col>
        <Col xs={24} md={18}>
          <div className="search-container">
            {`Showing all ${productList.length} results `}
            <Input
              placeholder="Search"
              className="search-input"
              onChange={(e) =>
                updateQuery({ ...query, name: e.target.value || undefined })
              }
            />
          </div>
          {productList.length > 0 ? (
            <Row>
              {productList.map((product) => (
                <Col xs={24} md={12} lg={8} xl={6}>
                  <ProductItem
                    roundCorner
                    showButton
                    className="m-3"
                    imgSrc={product.product_images[0]}
                    text={product.product_name}
                    onClick={() => navigate(`/products/${product.product_id}`)}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <div className="empty-container">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No Data</span>}
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

export default WithHOC(Products)
