import React from 'react'
import Carousel from "react-multi-carousel";

const MultiCarousel = props => {
  const {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    swipeable = false,
    draggable = false,
    showDots = false,
    infinite = false,
    autoPlay = false,
    autoPlaySpeed,
    customTransition,
    transitionDuration,
    className='',
    removeArrowOnDeviceType = [],
    dotListClass,
    itemClass,
    children
  } = props
  const responsive = {
    ...xxl ? { xxl: {
      breakpoint: { max: 9000, min: 1600 },
      items: xxl
    }} : {},
    ...xl ? { xl: {
      breakpoint: { max: 1599, min: 1200 },
      items: xl
    }} : {},
    ...lg ? { lg: {
      breakpoint: { max: 1199, min: 992 },
      items: lg
    }} : {},
    ...md ? { md: {
      breakpoint: { max: 991, min: 767 },
      items: md
    }} : {},
    ...sm ? { sm: {
      breakpoint: { max: 767, min: 576 },
      items: sm
    }} : {},
    ...xs ? { xs: {
      breakpoint: { max: 575, min: 0 },
      items: xs
    }} : {}
  };

  return (
    <Carousel
      responsive={responsive}
      swipeable={swipeable}
      draggable={draggable}
      showDots={showDots}
      infinite={infinite}
      autoPlay={autoPlay}
      autoPlaySpeed={autoPlaySpeed}
      keyBoardControl={false}
      customTransition={customTransition}
      transitionDuration={transitionDuration}
      containerClass={className}
      removeArrowOnDeviceType={removeArrowOnDeviceType}
      dotListClass={dotListClass}
      itemClass={itemClass}>
      {children}
    </Carousel>
  )
}

export default MultiCarousel