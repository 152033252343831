import React, { useState } from 'react'

import MultiCarousel from 'components/MultiCarousel'
import ProductItem from 'components/ProductItem'

import './index.scss'

const ImageDisplay = (props) => {
  let {
    images=[]
  } = props
  const [ selectedIndex, updateSelectedIndex ] = useState(0)
  return(
    <div className='image-display px-2'>
      <div className='px-3 mb-3'>
        <ProductItem
          imgSrc={images[selectedIndex]}
          text={''}
          className='bordered'
          />
      </div>
      <MultiCarousel xs={1} sm={2} md={3} lg={4} xl={4} xxl={4} infinite={true}>
        {
          images.map((val, index) => (
            <ProductItem
              imgSrc={val}
              text={''}
              className={`mx-3 bordered ${ selectedIndex === index ? 'selected' : '' }`}
              onClick={() => updateSelectedIndex(index)}/>
          ))
        }
      </MultiCarousel>
    </div>
  )
}

export default ImageDisplay