import React, { Component } from "react"
import _ from "lodash"
import { requestSuccess } from "utils/requestHandler"
import { Get, Post, Put, Delete } from "utils/axios"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      products: [],
      selectedProduct: {},
      showImageModal: false,
      showCreateModal: false,
      showUpdateModal: false,
      showDeleteModal: false,
    }

    onChangeProductHOC = (key, val) => this.setState({ [key]: val })

    load = (param) => this.setState({ loading: param })

    getProducts = () =>
      Get(
        `/product`,
        this.getProductsSuccess,
        ErrorHandler,
        this.load
      )
    getProductsSuccess = (payload) => {
      this.setState({ products: payload.data })
    }

    getProduct = (id, type) =>
      Get(
        `/product/${id}`,
        (payload) => this.getProductSuccess(payload, type),
        ErrorHandler,
        this.load
      )
    getProductSuccess = (payload, type) => {
      let tmp = _.cloneDeep(payload.data)
      tmp.original_categories = _.cloneDeep( payload.data.categories )
      tmp.categories = _.map( payload.data.categories, val => _.find( this.props.categories, { category_name: val }).category_id )
      tmp.original_subcategories = _.cloneDeep( payload.data.subcategories )
      let allSubcategories = []
      _.forEach( this.props.categories, val => {
        allSubcategories = [...allSubcategories, ...val.subcategory]
      })
      tmp.subcategories = _.map( payload.data.subcategories, val => _.find( allSubcategories, { subcategory_name: val }).subcategory_id)
      this.setState({
        selectedProduct: tmp,
        showUpdateModal: type === "update",
        showDeleteModal: type === "delete",
        showImageModal: type === "image",
      })
    }

    createProduct = (data) =>
      Post(
        `/product`,
        data,
        this.createProductSuccess,
        ErrorHandler,
        this.load
      )
    createProductSuccess = () => {
      requestSuccess("Created successfully")
      this.setState({ showCreateModal: false, selectedProduct: {} }, () => this.getProducts())
    }

    updateProduct = (data) =>
      Put(
        `/product/${this.state.selectedProduct.product_id}`,
        data,
        this.updateProductSuccess,
        ErrorHandler,
        this.load
      )
    updateProductSuccess = () => {
      requestSuccess("Updated successfully")
      this.setState({ showUpdateModal: false, selectedProduct: {} }, () => this.getProducts())
    }

    deleteProduct = () =>
      Delete(
        `/product/${this.state.selectedProduct.product_id}`,
        this.deleteProductSuccess,
        ErrorHandler,
        this.load
      )
    deleteProductSuccess = () => {
      requestSuccess("Deleted successfully")
      this.setState({ showDeleteModal: false, selectedProduct: {} }, () => this.getProducts())
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadProduct={this.state.loading}
          getProducts={this.getProducts}
          getProduct={this.getProduct}
          createProduct={this.createProduct}
          updateProduct={this.updateProduct}
          deleteProduct={this.deleteProduct}
          onChangeProductHOC={this.onChangeProductHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
