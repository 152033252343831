import React, { useEffect } from "react"
import { Form, Input, Button } from "antd"
import _ from 'lodash'

const DataForm = (props) => {
  const [form] = Form.useForm()
  useEffect(() => {
    if(!_.isEmpty(props.selectedCategory) && props.showUpdateModal){
      form.setFieldsValue(props.selectedCategory)
    }
  }, [])
  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      onFinish={props.onSubmit}
    >
      <Form.Item
        label="Name"
        name="category_name"
        rules={[{ required: true, message: "Please enter category name" }]}
      >
        <Input size="large" placeholder="Category Name" />
      </Form.Item>
      <Button type="primary" htmlType="submit" className="ml-auto">
        Save
      </Button>
    </Form>
  )
}

export default DataForm
