import React, { Component } from "react"
import _ from 'lodash'

import { Get } from "utils/axios"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      catalogueRequests: []
    }

    onChangeDataHOC = (key, val) => this.setState({ [key]: val })

    load = (param) => this.setState({ loading: param })

    getCatalogueRequests = () =>
      Get(
        `/products/catalogue/request`,
        this.getCatalogueRequestsSuccess,
        ErrorHandler,
        this.load
      )
    getCatalogueRequestsSuccess = (payload) => {
      this.setState({ catalogueRequests: payload.data })
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadData={this.state.loading}
          getCatalogueRequests={this.getCatalogueRequests}
          onChangeDataHOC={this.onChangeDataHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
