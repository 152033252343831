export const truncateTextWithEllipsis = ( inputHtml, maxChar ) => {
  if (maxChar == null || maxChar <= 0) {
    return inputHtml;
  }

  const plainText = inputHtml.replace(/<[^>]+>/g, '')
  if (plainText.length <= maxChar) {
    return inputHtml
  }

  let charCount = 0;
  const regex = /<[^>]+>|[^<]+/g;
  const truncatedHtml = inputHtml.replace(regex, match => {
    if (match.startsWith('<')) {
      return match;
    }
    
    const remainingChars = maxChar - charCount;
    if (match.length <= remainingChars) {
      charCount += match.length;
      return match;
    }

    const truncatedText = match.slice(0, remainingChars)
    charCount += truncatedText.length
    return truncatedText + '...';
  });

  return truncatedHtml;
}