import React, { useEffect } from "react"
import { Helmet } from 'react-helmet'
import { Card, Form, Input, Row, Col, Button } from "antd"
import _ from "lodash"

import LoadingOverlay from "components/LoadingOverlay"
import WithHOC from './actions'

const CompanyProfile = (props) => {
  const [form] = Form.useForm()

  useEffect(() => {
    props.getCompany()
  }, [])

  useEffect(() => {
    if(!_.isEmpty(props.selectedCompany)){
      form.setFieldsValue(props.selectedCompany)
    }
  }, [props.selectedCompany])

  const onSubmitForm = (formData) => {
    props.updateCompany(formData)
  }
  return (
    <div>
      <Helmet>
        <title>{"NF Company Profile"}</title>
      </Helmet>
      <Card>
        <Form form={form} autoComplete="off" layout="vertical" onFinish={onSubmitForm}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <h3>Edit Company Details</h3>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Address Line 1" name="address_line_1" rules={[{ required: true, message: 'Please enter address line 1' }]}>
                <Input size="large" placeholder="Address Line 1" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please enter email' }]}>
                <Input size="large" placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Address Line 2" name="address_line_2" rules={[{ required: true, message: 'Please enter address line 2' }]}>
                <Input size="large" placeholder="Address Line 2" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Contact No." name="contact_no" rules={[{ required: true, message: 'Please enter contact number' }]}>
                <Input size="large" placeholder="Contact No." />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Address Line 3" name="address_line_3" rules={[{ required: true, message: 'Please enter address line 3' }]}>
                <Input size="large" placeholder="Address Line 3" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Fax No." name="fax_no" rules={[{ required: true, message: 'Please enter fax no.' }]}>
                <Input size="large" placeholder="Fax No." />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Address Line 4" name="address_line_4" rules={[{ required: true, message: 'Please enter address line 4' }]}>
                <Input size="large" placeholder="Address Line 4" />
              </Form.Item>
            </Col>
          </Row>
          <Button className="ml-auto" type="primary" htmlType="submit">
            Save
          </Button>
        </Form>
      </Card>
      {props.onLoadCompany && <LoadingOverlay />}
    </div>
  )
}

export default WithHOC(CompanyProfile)
