import React, { Component } from "react"
import _ from 'lodash'

import { Get, Post } from "utils/axios"
import { requestSuccess } from "utils/requestHandler"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      reviews: [],
      reviewed: false,
      selectedProduct: {},
      showCatalogueForm: false,
      showEnquiryForm: false,
    }

    onChangeDataHOC = (key, val) => this.setState({ [key]: val })

    load = (param) => this.setState({ loading: param })

    getProduct = (id) =>
      Get(
        `/product/${id}`,
        this.getProductSuccess,
        ErrorHandler,
        this.load
      )
    getProductSuccess = (payload) => {
      this.setState({ selectedProduct: payload.data })
    }

    getReviews = (id) =>
      Get(
        `/product/${id}/review`,
        this.getReviewsSuccess,
        ErrorHandler,
        this.load
      )
      getReviewsSuccess = (payload) => {
      this.setState({ reviews: _.orderBy(payload.data, ['rating'], ['desc']) })
    }

    addReview = (id, data) =>
      Post(
        `/product/${id}/review`,
        data,
        payload => this.addReviewSuccess(payload, id),
        ErrorHandler,
        this.load
      )
    addReviewSuccess = (payload, id) => {
      this.setState({ reviewed: true })
      this.getReviews(id)
    }

    sendMessage = (data) =>
      Post(
        `/message/send`,
        data,
        this.sendMessageSuccess,
        ErrorHandler,
        this.load
      )
    sendMessageSuccess = () => {
      this.setState({ showEnquiryForm: false }, () => requestSuccess("Enquiry Sent!"))
    }

    requestCatalogue = (id, data) =>
      Post(
        `/product/${id}/catalogue/request`,
        data,
        this.requestCatalogueSuccess,
        ErrorHandler,
        this.load
      )
    requestCatalogueSuccess = () => {
      this.setState({ showCatalogueForm: false }, () => requestSuccess("Catalogue Sent! Please check your email."))
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadData={this.state.loading}
          getProduct={this.getProduct}
          getReviews={this.getReviews}
          addReview={this.addReview}
          sendMessage={this.sendMessage}
          requestCatalogue={this.requestCatalogue}
          onChangeDataHOC={this.onChangeDataHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
