import React from "react"
import { Modal, Button, Form, Input } from "antd"

const EnquiryForm = (props) => {
  const onSubmit = formData => {
    props.sendMessage( formData )
  }
  return (
    <Modal open footer={null} onCancel={props.onCancel}>
      <h4>Enquiry</h4>
      <Form name="review-form" onFinish={onSubmit} autoComplete="off" layout="vertical">
        <Form.Item
          label="Name"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your name",
            },
          ]}
        >
          <Input size="large" placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="Contact No."
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input your contact number",
            },
          ]}
        >
          <Input size="large" placeholder="Contact No." />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email",
            },
          ]}
        >
          <Input size="large" placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Subject"
          name="subject"
          rules={[
            {
              required: true,
              message: "Please input your subject",
            },
          ]}
        >
          <Input size="large" placeholder="Subject" />
        </Form.Item>
        <Form.Item
          label="Message"
          name="message"
          rules={[
            {
              required: true,
              message: "Please input your message",
            },
          ]}
        >
          <Input.TextArea placeholder="Tell Us Your Thoughts" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EnquiryForm
