import { Card, Form, Button, Input, ConfigProvider } from "antd"
import { useNavigate } from "react-router-dom"
import React, { useEffect } from "react"
import { Helmet } from 'react-helmet'

import { getItem, storeItem } from "utils/tokenStore"
import WithHOC from "./actions"

import "./index.scss"

const LoginPage = (props) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (getItem("LOGIN_TOKEN")) {
      props.checkToken(() => navigate("/dashboard/home"))
    }
  }, [])

  const onSubmit = (formData) => {
    props.login(formData, (val) => {
      storeItem("LOGIN_TOKEN", val)
      navigate("/dashboard/home")
    })
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#06004B",
          colorFillSecondary: "#EAEAEA",
        },
      }}
    >
      <Helmet>
        <title>{"NF Login"}</title>
      </Helmet>
      <div
        className="d-flex justify-content-center align-items-center h-100 login-bg"
        style={{ backgroundImage: "url(/login-bg.png)" }}
      >
        <Card className="login-container">
          <img className="logo" src="/logo-placeholder.png" />
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
              margin: "1.5rem 0",
            }}
            onFinish={onSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form>
        </Card>
      </div>
    </ConfigProvider>
  )
}

export default WithHOC(LoginPage)
