import React, { Component } from "react"
import { requestSuccess } from "utils/requestHandler"
import { Get, Put } from "utils/axios"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedCompany: {},
    }

    onChangeCompanyHOC = (key, val) => this.setState({ [key]: val })

    load = (param) => this.setState({ loading: param })

    getCompany = () =>
      Get(
        `/company/detail`,
        this.getCompanySuccess,
        ErrorHandler,
        this.load
      )
    getCompanySuccess = (payload) => {
      this.setState({
        selectedCompany: payload.data
      })
    }

    updateCompany = (data) =>
      Put(
        `/company/detail`,
        data,
        this.updateCompanySuccess,
        ErrorHandler,
        this.load
      )
    updateCompanySuccess = () => {
      requestSuccess("Updated successfully")
      this.getCompany()
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadCompany={this.state.loading}
          getCompany={this.getCompany}
          updateCompany={this.updateCompany}
          onChangeCompanyHOC={this.onChangeCompanyHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
