import { Row, Col, Card, Button, Form, Input, Result } from "antd"
import React from "react"
import { useOutletContext } from "react-router-dom"
import { Helmet } from 'react-helmet'

import WithHOC from "./actions"
import "./index.scss"

const Contact = (props) => {
  const { company } = useOutletContext()
  const onSubmit = (formData) => {
    props.sendMessage(formData)
  }
  return (
    <div>
      <Helmet>
        <title>{"NF Contact Us"}</title>
      </Helmet>
      <Row>
        <Col span={24}>
          <h3 className="text-center">Get In Touch</h3>
          <Card className="contact-us">
            <Row>
              <Col xs={24} md={12} className="left-part">
                <h2>Contact Us</h2>
                <div>{company.address_line_1}</div>
                <div>{company.address_line_2}</div>
                <div>{company.address_line_3}</div>
                <div>{company.address_line_4}</div>
                <br />
                <div>TEL: {company.contact_no}</div>
                <div>FAX: {company.fax_no}</div>
                <br />
                <div>EMAIL: {company.email}</div>
              </Col>
              <Col xs={24} md={12} className="right-part">
                {props.enquirySent ? (
                  <Result
                    status="success"
                    title="We received your enquiry"
                    subTitle="Our staff will contact you as soon as possible."/>
                ) : (
                  <Form
                    name="contact-us-form"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    onFinish={onSubmit}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Name"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your name!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact No."
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your contact number",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Subject"
                      name="subject"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the subject!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Your Message"
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your message!",
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                      wrapperCol={{
                        offset: 21,
                        span: 24,
                      }}
                    >
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h3 className="text-center">Drop by our store</h3>
          <iframe
            src="https://storage.googleapis.com/maps-solutions-quqf7yx2v4/locator-plus/4e4k/locator-plus.html"
            width="100%"
            height="500px"
            loading="lazy"
          ></iframe>
        </Col>
      </Row>
    </div>
  )
}

export default WithHOC(Contact)
