import React from 'react'
import { DownOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons'
import { Dropdown, Avatar, Space } from 'antd'
import { useNavigate } from "react-router-dom"

import { storeItem } from 'utils/tokenStore'

const Userbox = () => {
  const navigate = useNavigate()
  const menuItem = {
    items: [
      {
				key: "logout",
				icon: <PoweroffOutlined />,
				label: "Log Out"
      }
    ],
    onClick: (e) => {
      switch(e.key){
        case "logout":
          storeItem("LOGIN_TOKEN", "")
          navigate('/login')
          break
        default: 
      }
    }
  }

  return (
    <Dropdown menu={menuItem} trigger={'click'}>
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <Avatar size={32} icon={<UserOutlined />} />
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  )
}

export default Userbox