import React, { useEffect } from "react"
import { Table, Space, Button, Dropdown, Modal, Tag } from "antd"
import { Helmet } from 'react-helmet'

import LoadingOverlay from "components/LoadingOverlay"
import { TagColor } from "utils/TagColor"
import WithHOC from "./actions"
import DataForm from "./form"
import SubCategories from "./Subcategory"

const actionItem = [
  {
    key: 1,
    label: "Update",
  },
  {
    key: 2,
    label: "Manage Subcategory",
  },
  {
    key: 3,
    label: "Delete",
  },
]

const Categories = (props) => {
  let {
    selectedCategory,
    showCreateModal,
    showUpdateModal,
    showDeleteModal,
    showSubCategoryModal,
    getCategory,
    getCategories,
    createCategory,
    updateCategory,
    deleteCategory,
    onChangeCategoryHOC
  } = props

  useEffect(() => {
    if( !showSubCategoryModal ){
      getCategories()
      onChangeCategoryHOC("selectedCategory", {})
    }
  }, [showSubCategoryModal])

  const onCreate = (formData) => {
    createCategory(formData)
  }

  const onUpdate = (formData) => {
    updateCategory(formData)
  }

  const onDelete = () => {
    deleteCategory()
  }

  const actionFunction = (key, ...args) => {
    switch (key) {
      case "1":
        getCategory(args[0].category_id, 'update')
        break
      case "2":
        getCategory(args[0].category_id, 'subcategory')
        break
      case "3":
        getCategory(args[0].category_id, 'delete')
        break
      default:
    }
  }

  const columns = [
    {
      key: "category_name",
      title: "Category Name",
      dataIndex: "category_name",
    },
    {
      key: "subcategory",
      title: "Subcategory",
      dataIndex: "subcategory",
      render: (text, record) => {
        return text.map((item, index) => {
          return (
            <Tag color={TagColor[index%11]} key={index}>
              {item.subcategory_name}
            </Tag>
          )
        })
      }
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: actionItem,
              onClick: (val) => actionFunction(val.key, record),
            }}
            trigger={["click"]}
          >
            <Button>Actions</Button>
          </Dropdown>
        </Space>
      ),
    },
  ]

  return (
    <div>
      <Helmet>
        <title>{"NF Product Categories Management"}</title>
      </Helmet>
      <Button type="primary" className="mb-2 ml-auto" onClick={() => onChangeCategoryHOC("showCreateModal", true)}>
        Add Category
      </Button>
      <Table dataSource={props.categories} columns={columns} rowKey={"id"} />
      {
        showCreateModal && (
          <Modal
            open={true}
            title="Create Category"
            footer={false}
            onCancel={() => onChangeCategoryHOC("showCreateModal", false)}
          >
            <DataForm {...props} onSubmit={onCreate} />
          </Modal>
        )
      }
      {
        showUpdateModal && (
          <Modal
            open={true}
            title="Update Category"
            footer={false}
            onCancel={() => onChangeCategoryHOC("showUpdateModal", false)}
          >
            <DataForm {...props} onSubmit={onUpdate} />
          </Modal>
        )
      }
      <Modal
        open={showDeleteModal}
        title="Delete Category"
        onOk={onDelete}
        onCancel={() => onChangeCategoryHOC("showDeleteModal", false)}
      >
        Are you sure you want to delete this category?
      </Modal>
      <Modal
        open={showSubCategoryModal}
        title="Sub-Category"
        footer={false}
        onCancel={() => onChangeCategoryHOC("showSubCategoryModal", false)}
      >
        <SubCategories selectedCategory={selectedCategory} getCategory={getCategory} />
      </Modal>
      {props.onLoadCategory && <LoadingOverlay />}
    </div>
  )
}

export default WithHOC(Categories)
