import React from "react"
import { Tabs, Card } from "antd"
import Products from "./Products"
import Categories from "./Categories"

const ProductManagement = () => {
  const items = [
    {
      key: '1',
      label: 'Products',
      children: <Products/>,
    },
    {
      key: '2',
      label: 'Categories',
      children: <Categories/>,
    },
  ];
  return (
    <Card>
      <h3>Product Management</h3>
      <Tabs defaultActiveKey="1" items={items} />
    </Card>
  )
}

export default ProductManagement
