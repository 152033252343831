import React, { useEffect } from "react"
import { Card, Statistic } from "antd"
import { Helmet } from 'react-helmet'

import WithHOC from "./actions"

const MainPage = (props) => {
  useEffect(() => {
    props.getVisitor()
  }, [])
  return (
    <div style={{ width: "12rem"}}>
      <Helmet>
        <title>{"NF Dashboard"}</title>
      </Helmet>
      <Card bordered={false} className="p-0 text-center">
        <Statistic
          title="Visitor Count"
          value={props.visitors.length}
        />
      </Card>
    </div>
  )
}

export default WithHOC(MainPage)
