import React, { useEffect } from "react"
import { Helmet } from 'react-helmet'
import { Table, Card } from "antd"
import _ from "lodash"

import LoadingOverlay from "components/LoadingOverlay"
import WithHOC from "./actions"

const CatalogueRequest = (props) => {
  let {
    onLoadData,
    catalogueRequests,
    getCatalogueRequests
  } = props

  useEffect(() => {
    getCatalogueRequests()
  }, [])

  const columns = [
    {
      key: "username",
      title: "Name",
      dataIndex: "username",
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
    },
    {
      key: "requested_date",
      title: "Requested Date",
      dataIndex: "requested_date"
    },
  ]

  return (
    <Card>
      <Helmet>
        <title>{"NF Catalogue Request"}</title>
      </Helmet>
      <h3>Catalogue Requests</h3>
      <Table dataSource={catalogueRequests} columns={columns} rowKey={"id"} />
      {onLoadData && <LoadingOverlay />}
    </Card>
  )
}

export default WithHOC(CatalogueRequest)
