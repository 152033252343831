import React, { useEffect } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import {
  LaptopOutlined,
  BankOutlined,
  ShopOutlined,
  SettingOutlined,
  FilePdfOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons"

import Dashboard from "./containers/Dashboard"
import LoginPage from "containers/Login"
import PublicPage from "containers/Public"
import HomePage from "containers/Public/00-Home"
import AboutUs from "containers/Public/01-AboutUs"
import Products from "containers/Public/02-Products"
import Gallery from "containers/Public/03-Gallery"
import Contact from "containers/Public/04-Contact"
import ProductDetails from "containers/Public/02-Products/ProductDetails"
import MainPage from "containers/Dashboard/MainPage"
import CompanyProfile from "containers/Dashboard/CompanyProfile"
import ProductManagement from "containers/Dashboard/ProductManagement"
import BannerConfiguration from "containers/Dashboard/BannerConfiguration"
import CatalogueRequest from "containers/Dashboard/CatalogueRequest"

import { Post } from "utils/axios"
import BlockList from "containers/Dashboard/BlockList"

export const router = [
  {
    path: "/",
    label: "Public",
    element: <PublicPage />,
    children: [
      {
        key: "/",
        label: "Home",
        path: "/",
        element: <HomePage />,
      },
      {
        label: "About Us",
        key: "/about-us",
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        label: "Products",
        key: "/products",
        path: "/products",
        element: <Products />,
      },
      {
        label: "Gallery",
        key: "/gallery",
        path: "/gallery",
        element: <Gallery />,
      },
      {
        label: "Contact",
        key: "/contact",
        path: "/contact",
        element: <Contact />,
      },
      {
        label: "Product Details",
        parent: "/products",
        path: "/products/:id",
        element: <ProductDetails />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/dashboard/",
    label: "Dashboard",
    element: <Dashboard />,
    children: [
      {
        key: "home",
        path: "home",
        icon: <LaptopOutlined />,
        label: "Dashboard",
        element: <MainPage />,
      },
      {
        key: "company-details",
        path: "company-details",
        icon: <BankOutlined />,
        label: "Company Details",
        element: <CompanyProfile />,
      },
      {
        key: "product-management",
        path: "product-management",
        icon: <ShopOutlined />,
        label: "Product Management",
        element: <ProductManagement />,
      },
      {
        key: "banner-configuration",
        path: "banner-configuration",
        icon: <SettingOutlined />,
        label: "Banner Configuration",
        element: <BannerConfiguration />,
      },
      {
        key: "catalogue-requests",
        path: "catalogue-requests",
        icon: <FilePdfOutlined />,
        label: "Catalogue Requests",
        element: <CatalogueRequest/>
      },
      {
        key: "block-lists",
        path: "block-lists",
        icon: <ExclamationCircleOutlined />,
        label: "Block Lists",
        element: <BlockList/>
      },
    ],
  },
]
const recordVisitor = () => {
  if( !window.location.pathname.includes('dashboard') ) {
    Post(
      `/visitor-record`,
      { visit_page: window.location.pathname },
      () => {},
      () => {},
      () => {}
    )
  }
}

const MainRouter = () => {
  let recorded = false
  useEffect(() => {
    if (!recorded) {
      recorded = true
      recordVisitor()
    }
  }, [])
  return <RouterProvider router={createBrowserRouter(router)} />
}

export default MainRouter
