import React, { Component } from "react"
import _ from "lodash"
import { requestSuccess } from "utils/requestHandler"
import { Get, Post, Put, Delete } from "utils/axios"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      banners: [],
      selectedBanner: {},
      uploadedImages: [],
      showImageModal: false,
      showCreateModal: false,
      showDeleteModal: false,
    }

    onChangeBannerHOC = (key, val) => this.setState({ [key]: val })

    load = (param) => this.setState({ loading: param })

    getBanners = () =>
      Get(
        `/banner`,
        this.getBannersSuccess,
        ErrorHandler,
        this.load
      )
    getBannersSuccess = (payload) => {
      this.setState({ banners: _.orderBy(payload.data, ['order'], ['asc']) })
    }

    createBanner = () =>
      Post(
        `/banner`,
        { banner_blob: this.state.uploadedImages[0] },
        this.createBannerSuccess,
        ErrorHandler,
        this.load
      )
    createBannerSuccess = () => {
      requestSuccess("Added successfully")
      this.setState({ showCreateModal: false, uploadedImages: [] }, () => this.getBanners())
    }

    updateBanner = (data) =>
      Put(
        `/banner/${data.banner_id}`,
        data,
        this.updateBannerSuccess,
        ErrorHandler,
        this.load
      )
    updateBannerSuccess = () => {
      this.getBanners()
    }

    deleteBanner = () =>
      Delete(
        `/banner/${this.state.selectedBanner.banner_id}`,
        this.deleteBannerSuccess,
        ErrorHandler,
        this.load
      )
    deleteBannerSuccess = () => {
      requestSuccess("Deleted successfully")
      this.setState({ showDeleteModal: false, selectedBanner: {} }, () => this.getBanners())
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadBanner={this.state.loading}
          getBanners={this.getBanners}
          createBanner={this.createBanner}
          updateBanner={this.updateBanner}
          deleteBanner={this.deleteBanner}
          onChangeBannerHOC={this.onChangeBannerHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
