import React from 'react'
import { Button } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { CloseOutlined, MenuOutlined } from '@ant-design/icons'

import Userbox from './Userbox'
// import Cart from './Cart'
import { toggle } from 'reducers/SideMenu'
import "./index.scss"

const Header = () => {
  const isMenuCollapsed = useSelector((state) => state.sideMenu.isCollapsed)
	const dispatch = useDispatch()
  return(
    <div className='header-container'>
      <div className='d-flex align-items-center justify-content-center h-100'>
        <Button
          ghost
          type='primary'
          className="me-2"
          onClick={() => dispatch(toggle())}>
          {isMenuCollapsed ? <MenuOutlined /> : <CloseOutlined />}
        </Button>
        <div className='h-100 p-2'><img className='h-100' src="/logo-placeholder.png"/></div>
      </div>
      <div className='d-flex align-items-center'>
        {/* <Cart/> */}
        <Userbox/>
      </div>
    </div>
  )
}

export default Header