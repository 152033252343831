import React from "react"
import { Table, Space, Button, Dropdown, Modal } from "antd"

import LoadingOverlay from "components/LoadingOverlay"
import WithHOC from "../actions/subcategory"
import DataForm from "./form"

const actionItem = [
  {
    key: 1,
    label: "Update",
  },
  {
    key: 2,
    label: "Delete",
  },
]

const SubCategories = (props) => {
  let {
    showCreateModal,
    showUpdateModal,
    showDeleteModal,
    getSubCategory,
    createSubCategory,
    updateSubCategory,
    deleteSubCategory,
    onChangeSubCategoryHOC
  } = props

  const onCreate = (formData) => {
    createSubCategory({...formData, category_id: props.selectedCategory.category_id })
  }

  const onUpdate = (formData) => {
    updateSubCategory({...formData, category_id: props.selectedSubCategory.category_id })
  }

  const onDelete = () => {
    deleteSubCategory()
  }

  const actionFunction = (key, ...args) => {
    switch (key) {
      case "1":
        getSubCategory(args[0].subcategory_id, 'update')
        break
      case "2":
        getSubCategory(args[0].subcategory_id, 'delete')
        break
      default:
    }
  }

  const columns = [
    {
      key: "subcategory_name",
      title: "Name",
      dataIndex: "subcategory_name",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: actionItem,
              onClick: (val) => actionFunction(val.key, record),
            }}
            trigger={["click"]}
          >
            <Button>Actions</Button>
          </Dropdown>
        </Space>
      ),
    },
  ]

  return (
    <div>
      <Button type="primary" className="mb-2 ml-auto" onClick={() => onChangeSubCategoryHOC("showCreateModal", true)}>
        Add Sub-Category
      </Button>
      <Table dataSource={props.selectedCategory.subcategory} columns={columns} rowKey={"id"} />
      {
        showCreateModal && (
          <Modal
            open={true}
            title="Create Sub-Category"
            footer={false}
            onCancel={() => onChangeSubCategoryHOC("showCreateModal", false)}
          >
            <DataForm {...props} onSubmit={onCreate} />
          </Modal>
        )
      }
      {
        showUpdateModal && (
          <Modal
            open={true}
            title="Update Sub-Category"
            footer={false}
            onCancel={() => onChangeSubCategoryHOC("showUpdateModal", false)}
          >
            <DataForm {...props} onSubmit={onUpdate} />
          </Modal>
        )
      }
      <Modal
        open={showDeleteModal}
        title="Delete Sub-Category"
        onOk={onDelete}
        onCancel={() => onChangeSubCategoryHOC("showDeleteModal", false)}
      >
        Are you sure you want to delete this sub-category?
      </Modal>
      {props.onLoadSubCategory && <LoadingOverlay />}
    </div>
  )
}

export default WithHOC(SubCategories)
