import React, { useEffect } from 'react'
import _ from 'lodash'
import { Menu, ConfigProvider } from 'antd'
import { router } from 'router'
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import { useSelector } from 'react-redux'

import Header from 'components/Header'
import { getItem } from 'utils/tokenStore'
import WithHOC from "./actions"

import './index.scss'

const Dashboard = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isMenuCollapsed = useSelector((state) => state.sideMenu.isCollapsed)
  const menuItems = _.find( router, { label: "Dashboard" } ).children

  useEffect(() => {
    if(!getItem("LOGIN_TOKEN")){
      navigate('/login')
    } else {
      props.checkToken(() => navigate('/login'))
    }
    if( location.pathname === '/dashboard'){
      navigate('/dashboard/home')
    }
  }, [])

  const navigateTo = (item) => {
    navigate('/dashboard/' + item.keyPath.reverse().join("/"))
  }

  const getSelectedKey = (isOpenKey) => {
    let tmp = window.location.pathname.split("/")
    let tmpIndex = tmp.indexOf("dashboard")
    if( tmpIndex > -1 ){
      tmp.splice( 0, tmpIndex + 1)
    } else {
      return []
    }
    if( isOpenKey ){
      return tmp.slice(0, -1)
    } else {
      return tmp.slice(-1)
    }
  }

  return(
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#06004B'
        }
      }}>
      <div className="dashboard-container">
        <Header/>
        <div className="dashboard-content-container">
          <Menu
            onClick={navigateTo}
            inlineCollapsed={isMenuCollapsed}
            defaultOpenKeys={getSelectedKey(true)}
            selectedKeys={getSelectedKey()}
            mode="inline"
            items={menuItems}
          />
          <div className="dashboard-content">
            <Outlet />
          </div>
        </div>
      </div>
    </ConfigProvider>
  )
}

export default WithHOC(Dashboard)