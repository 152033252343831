import { createSlice } from '@reduxjs/toolkit'

export const cartSlice = createSlice({
  name: 'carts',
  initialState: {
    items: [],
  },
  reducers: {
    add: (state, action) => {
      state.items.push( action.payload )
    },
    remove: (state, action) => {
      state.items.splice( action.payload, 1 )
    }
  },
})

// Action creators are generated for each case reducer function
export const { add, remove } = cartSlice.actions

export default cartSlice.reducer