import React, { Component } from "react"

import { Get } from "utils/axios"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
    }

    load = (param) => this.setState({ loading: param })

    checkToken = (callback) =>
      Get(
        `/protected`,
        () => {},
        error => this.checkTokenError(error, callback),
        this.load
      )
    checkTokenError = (error, callback) => {
      ErrorHandler(error)
      callback()
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadImage={this.state.loading}
          checkToken={this.checkToken}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
