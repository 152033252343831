import React from "react"
import { Button } from "antd"
import './index.scss'

const ProductItem = (props) => {
  let {
    text,
    onClick,
    imgSrc,
    className,
    showButton,
    roundCorner
  } = props

  return (
    <div className={className||''}>
      <div className="position-relative">
        <div className={`product-item square-content`} onClick={() => !showButton && onClick && onClick() }>
          <div className="content-inside">
            <div className="product-image">
              <img src={imgSrc} className={ roundCorner ? 'round-corner' : '' } />
              { showButton && onClick && <Button 
                type="primary" 
                className='learn-more-btn'
                onClick={ onClick }>
                Learn More
              </Button>
              }
            </div>
          </div>
        </div>
      </div>
      <div>
        {text}
      </div>
    </div>
  )
}

export default ProductItem
