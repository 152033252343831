import { createSlice } from '@reduxjs/toolkit'

export const SideMenuSlice = createSlice({
  name: 'side-menu',
  initialState: {
    isCollapsed: false,
  },
  reducers: {
    toggle: (state) => {
      state.isCollapsed = !state.isCollapsed
    }
  },
})

// Action creators are generated for each case reducer function
export const { toggle } = SideMenuSlice.actions

export default SideMenuSlice.reducer