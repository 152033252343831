import React, { useEffect } from "react"
import { Card, Row, Col, Button, Modal } from "antd"
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
} from "@ant-design/icons"
import _ from "lodash"
import { FilePond } from "react-filepond"
import { Helmet } from 'react-helmet'

import LoadingOverlay from "components/LoadingOverlay"
import WithHOC from "./actions"
import "./index.scss"

const BannerConfiguration = (props) => {
  const {
    banners,
    uploadedImages,
    onLoadBanner,
    showCreateModal,
    showDeleteModal,
    createBanner,
    updateBanner,
    deleteBanner,
    onChangeBannerHOC,
  } = props

  useEffect(() => {
    props.getBanners()
  }, [])

  return (
    <div className="banner-configuration">
      <Helmet>
        <title>{"NF Banner Configuration"}</title>
      </Helmet>
      <Card>
        <div className="mb-4 d-flex justify-content-between">
          <h3>Edit Banner Details</h3>
          <Button
            type="primary"
            onClick={() => onChangeBannerHOC("showCreateModal", true)}
          >
            Add New Banner
          </Button>
        </div>
        <Row gutter={16}>
          {banners.map((banner, index) => (
            <Col xs={24} sm={12} md={6} lg={4}>
              <div className="square-content">
                <img src={banner.banner_blob} />
                <div className="util-overlay">
                  <div className="d-flex h-100 justify-content-center align-items-center">
                    <Button
                      type="text"
                      disabled={index === 0}
                      onClick={() => {
                        updateBanner({ ...banner, order: index - 1 })
                        updateBanner({ ...banners[index - 1], order: index })
                      }}
                    >
                      <ArrowLeftOutlined
                        style={{
                          color: index === 0 ? "#b6b6b6" : "#ffffff",
                          fontSize: "2rem",
                        }}
                      />
                    </Button>
                    <Button
                      type="text"
                      onClick={() => {
                        onChangeBannerHOC("selectedBanner", banner)
                        onChangeBannerHOC("showDeleteModal", true)
                      }}
                    >
                      <DeleteOutlined
                        style={{ color: "#ff0000", fontSize: "2rem" }}
                      />
                    </Button>
                    <Button
                      type="text"
                      disabled={index === banners.length - 1}
                      onClick={() => {
                        updateBanner({ ...banner, order: index + 1 })
                        updateBanner({ ...banners[index + 1], order: index })
                      }}
                    >
                      <ArrowRightOutlined
                        style={{
                          color:
                            index === banners.length - 1
                              ? "#b6b6b6"
                              : "#ffffff",
                          fontSize: "2rem",
                        }}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Card>
      <Modal
        open={showCreateModal}
        title="Add Banner Image"
        footer={null}
        onCancel={() => onChangeBannerHOC("showCreateModal", false)}
      >
        <FilePond
          files={uploadedImages}
          className="mb-4"
          acceptedFileTypes={["image/*"]}
          onupdatefiles={(value) => {
            if (!_.isEmpty(value)) {
              const reader = new FileReader()
              reader.onload = () => {
                onChangeBannerHOC("uploadedImages", [reader.result])
              }
              reader.readAsDataURL(value[0]?.file)
            } else {
              onChangeBannerHOC("uploadedImages", [])
            }
          }}
          allowMultiple={false}
        />
        <Button
          className="ml-auto"
          disabled={_.isEmpty(uploadedImages)}
          onClick={() => createBanner()}
        >
          Add
        </Button>
      </Modal>
      <Modal
        open={showDeleteModal}
        title="Delete Product"
        onOk={() => deleteBanner()}
        onCancel={() => onChangeBannerHOC("showDeleteModal", false)}
      >
        Are you sure you want to delete this banner image?
      </Modal>
      {onLoadBanner && <LoadingOverlay />}
    </div>
  )
}

export default WithHOC(BannerConfiguration)
