import React, { Component } from "react"
import { requestSuccess } from "utils/requestHandler"
import { Get, Post, Delete } from "utils/axios"
import { ErrorHandler } from "utils/requestError"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      uploadedImages: [],
      productImages: [],
      selectedImage: {},
      showDeleteModal: false
    }

    load = (param) => this.setState({ loading: param })

    onChangeImageHOC = (key, val) => this.setState({ [key]: val })


    getImages = (data) =>
      Get(
        `/product/${this.props.selectedProduct.product_id}/image`,
        this.getImagesSuccess,
        ErrorHandler,
        this.load
      )
      getImagesSuccess = (payload) => {
      this.setState({ productImages: payload.data })
    }

    addImages = (data) =>
      Post(
        `/product/${this.props.selectedProduct.product_id}/image`,
        { product_id: this.props.selectedProduct.product_id, image_blob: data[0] },
        this.addImagesSuccess,
        ErrorHandler,
        this.load
      )
    addImagesSuccess = () => {
      requestSuccess("Added successfully")
      this.setState({ uploadedImages: [] })
      this.getImages()
    }

    deleteImages = () =>
      Delete(
        `/product/${this.props.selectedProduct.product_id}/image/${this.state.selectedImage.id}`,
        this.deleteImagesSuccess,
        ErrorHandler,
        this.load
      )
    deleteImagesSuccess = () => {
      requestSuccess("Deleted successfully")
      this.setState({ showDeleteModal: false })
      this.getImages()
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadImage={this.state.loading}
          getImages={this.getImages}
          addImages={this.addImages}
          deleteImages ={this.deleteImages}
          onChangeImageHOC={this.onChangeImageHOC}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
